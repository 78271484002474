import { weddingsApiKey } from '@settings';
import { Logger } from '@utils/logger/logger';
import WeddingSDK from '@xo-union/sdk-weddings';
import type { GetDecisionsParams } from './types';

export const getDecisions = async ({
	token,
	weddingId,
}: GetDecisionsParams) => {
	const quickFilterHeaders = new Headers();
	quickFilterHeaders.append('Content-Type', 'application/json');
	quickFilterHeaders.append('membership-session-token', token);

	const weddingClient = new WeddingSDK({
		apiKey: weddingsApiKey,
		membershipSessionToken: token,
	});

	try {
		const result = await weddingClient.getDecisions(weddingId);
		return result;
	} catch (error) {
		Logger.error({
			log_message:
				'getDecisions falied: Error getting decisions from wedding SDK for quick filters',
			errorToLog: error,
			newRelic: true,
		});
	}
};
